import { en } from "./en";
import { pt } from "./pt";

/**
 * This variable should support all the possible values in LANGS_AVAILABLE[].languageCode (in file app-constants.js)
 */
const LANGS_DICTIONARY = {
  en: en,
  pt: pt,
};

export default class TranslationsClass {
  lang: string;
  dictionary: {
    [key: string]: { lang: string; messages: { [key: string]: string } };
  };
  constructor(userLang: string) {
    this.dictionary = { ...LANGS_DICTIONARY };
    this.lang = this._getSafeLang(userLang);
  }

  _getSafeLang(lang: string) {
    let safeLang = "en";
    if (this.dictionary.hasOwnProperty(lang)) {
      safeLang = lang;
    }
    return safeLang;
  }

  setNewLanguage(newLanguageCode: string) {
    // to be called in reducer settings, when user change the language
    // this will immediatly force translations with new language, without the need to refresh page
    this.lang = this._getSafeLang(newLanguageCode);
  }

  /**
   *
   * @param {String} keyToTranslate
   * @param {Object} args OPTIONAL are the extra values to be replaced, in the format { key1: value1, key2: value2, ... }
   */
  translate(keyToTranslate: string, args?: { [x: string]: string }) {
    let translatedValue = this.dictionary[this.lang].messages[keyToTranslate];
    if (typeof translatedValue === "undefined" || translatedValue === "") {
      // console.info(
      //   "New key to translate (in lang=",
      //   this.lang,
      //   "): ",
      //   keyToTranslate
      // );
      translatedValue = keyToTranslate;
    }
    if (typeof args !== "undefined") {
      translatedValue = this._replaceArgs(translatedValue, args);
    }
    return translatedValue;
  }

  _replaceArgs(target: string, args: { [x: string]: string }) {
    Object.keys(args).forEach((key) => {
      target = target.replace(new RegExp("{{" + key + "}}", "g"), args[key]);
    });
    return target;
  }
}
