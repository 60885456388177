import { Component, Suspense, lazy } from "react";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { changeLanguage, changeWebsite } from "./redux/actions/action-settings";
import { connect } from "react-redux";
import ResizeObserver from 'react-resize-detector';

// lazy import like old docs: https://legacy.reactjs.org/docs/code-splitting.html
// @ts-ignore
const Register = lazy(() => import("./views/register/register"));
// @ts-ignore
const FinishRegister = lazy(() => import("./views/finishRegister/finishRegister"));
// @ts-ignore
const ChangePassword = lazy(() => import("./views/changePassword/changePassword"));

type Props = RouteComponentProps<{}> &
ReturnType<typeof mapDispatchToProps>;


class App extends Component<Props> {
  constructor(props: any) {
    super(props);
    window.addEventListener("message", (a) => {
      if (a.data.theme){ 
        const theme = a.data.theme; 
        if (a.data.uri !== this.props.history.location.pathname){
          this.props.history.push(a.data.uri);
        }
        Object.keys(theme.vars).forEach((key) => {
          document.documentElement.style.setProperty(key, theme.vars[key]);
        });
        if (theme.fonts){
          Object.values(theme.fonts).forEach((fontData: any) => {
            const linkId = `font-${fontData.id}`;
            if (document.getElementById(linkId)){
              return;
            }
            const link = document.createElement('link');
            link.id = linkId
            link.setAttribute('rel', 'stylesheet');
            link.setAttribute('type', 'text/css');
            link.setAttribute('href', fontData.url);
            document.head.appendChild(link);
          });
        }
        this.props.updateLang(a.data.lang);
        this.props.changeWebsite(a.data.appName);
      }
      if (a.data.gtagManager && a.data.gtagManager.length > 1) {
        let scriptGTM = document.createElement('script');
        scriptGTM.type = 'text/javascript';
        const code = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','"+a.data.gtagManager+"');";
        try {
          scriptGTM.appendChild(document.createTextNode(code));
          document.body.appendChild(scriptGTM);
        } catch (e) {
          scriptGTM.text = code;
          document.body.appendChild(scriptGTM);
        }
      }
    }, false)
  };

  onResize = (width?:number, height?:number) => {
    if (window.parent){
      window.parent.postMessage({
        height: height
    }, "*");
    }
  };

  render() {
    return (
      <div className="App">
        <Suspense fallback={<div className="loader"></div>}>
          <Switch>
            <Route path="/" exact component={Register} />
            <Route path="/user-confirmation" component={FinishRegister} />
            <Route path="/user-forgot" component={ChangePassword} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
        <ResizeObserver 
          refreshMode={'throttle'}
          refreshRate={50}
          handleHeight
          onResize={this.onResize} />
      </div>
    );
  }
}



const mapDispatchToProps = (dispatch: any) => {
  return {
    updateLang: (lang: string) => {
      dispatch(changeLanguage(lang));
    },
    changeWebsite: (name: string) => {
      dispatch(changeWebsite(name));
    }
  };
};

export default connect(null, mapDispatchToProps)(withRouter(App));