import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { reducer as settingsReducer } from "./reducers/reducer-settings";
import { reducer as registerReducer } from "./reducers/reducer-register";
import { INITIAL_STATE } from "./reducers/initial-state";
import { loadState, saveState } from "./storage/local-storage";
import merge from "lodash/merge";
import throttle from "lodash/throttle";
import TranslationsClass from "src/utils/translation/translation-class";

// let shouldClearCache = localStorage.getItem("shouldClearCache");
// let persistedState = { ...INITIAL_STATE };

// if (shouldClearCache === "true") {
//   clearLocalStorage();
//   localStorage.removeItem("shouldClearCache");
// } else {
//   persistedState = merge(persistedState, { ...loadState() });
// }

// const enhancers = [];
// const windowIfDefined = typeof window === "undefined" ? null : (window as any);
// if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
//   enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
// }

let persistedState = { ...INITIAL_STATE };
let shouldClearCache = true;

// check if shouldClearCache
let lastActivityTimestamp =
  Number(localStorage.getItem("shouldClearCache")) * 1;
if (lastActivityTimestamp > 0) {
  const nowInUserTimezone = new Date();
  const nowMilliseconds = nowInUserTimezone.getTime();
  shouldClearCache =
    nowMilliseconds - lastActivityTimestamp >
    1440 * // hammerd, must be on process.env
      60 *
      1000;
}

if (shouldClearCache === false || 1) {
  // Case 1: use localstorage information merged with initial state
  // using lodash.merge function to get persisted state from localstorage, and fill the rest of the values with the initial state
  // this is to prevent old users from crashing the site (with new features that needed extra variables in the app state)
  persistedState = merge({ ...INITIAL_STATE }, { ...loadState() });
} else {
  // Case 2: do not use localstorage
  clearLocalStorage(); // ensure localstorage gets cleared
  persistedState = { ...INITIAL_STATE };
}

const reducers = combineReducers({
  register: registerReducer,
  settings: settingsReducer,
});

const store = createStore(reducers, persistedState, applyMiddleware(thunk));

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

export function clearLocalStorage() {
  localStorage.removeItem("state");
}

let loadUserLang: string;
try {
  loadUserLang = persistedState.settings.userLang;
} catch (error) {
  loadUserLang = "en";
}

export const _t = new TranslationsClass(loadUserLang);

export default store;
