import { Action, Reducer } from "redux";
import { Register } from "src/model/myTypes";
import { InitialStateRegister } from "./initial-state";
import { RegisterActions } from "../actions/action-register";

const unloadedState: Register = InitialStateRegister;

export const reducer: Reducer<Register> = (
  state: Register | undefined,
  incomingAction: Action
): Register => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as RegisterActions;
  switch (action.type) {
    case "POST_REGISTER_START":
      return {
        ...state,
        registerStartResponse: action.payload.success,
        registerStartMessage: action.payload.message,
        registerStartMessageErrorFields: action.payload.fields,
      };
    case "IS_USERNAME_FREE":
      return {
        ...state,
        usernameFree: action.payload.isFree,
      };
    case "UPDATE_AGENT_INFO":
      const agentRegisterMessage = (
        action.payload.hasOwnProperty('registerMessage') 
        && action.payload.registerMessage.hasOwnProperty(action.currentLang)
       ) ? action.payload.registerMessage[action.currentLang] : '';
      return {
        ...state,
        availableCurrencies: action.payload.hasOwnProperty('currencies') ? action.payload.currencies : ["BRL"],
        requestDocumentsAddress: action.payload.hasOwnProperty('requestDocumentsAddress') ? action.payload.requestDocumentsAddress : false,
        requestDocumentsIdentity: action.payload.hasOwnProperty('requestDocumentsIdentity') ? action.payload.requestDocumentsIdentity : false,
        autoApproveUser: action.payload.hasOwnProperty('autoApproveUser') ? action.payload.autoApproveUser : true,
        phoneNumberResgistration: action.payload.hasOwnProperty('phoneNumberResgistration') ? action.payload.phoneNumberResgistration : true,
        birthdateResgistration: action.payload.hasOwnProperty('birthdateResgistration') ? action.payload.birthdateResgistration : true,
        cpfResgistration: action.payload.hasOwnProperty('cpfResgistration') ? action.payload.cpfResgistration : true,
        forceEmailValidation: action.payload.hasOwnProperty('forceEmailValidation') ? action.payload.forceEmailValidation : false,
        useLegitimuz: action.payload.hasOwnProperty('useLegitimuz') ? action.payload.useLegitimuz : false,
        agentRegisterMessage: agentRegisterMessage
      };
    case "IS_VALID_HASH": {
      return {
        ...state,
        isValidHash: action.payload.isValid,
        hash: action.payload.hash,
        preData: action.payload.preData,
      };
    }
    case "RESET_RESPONSE": {
      return {
        ...state,
        registerStartResponse: false,
        registerStartMessage: "",
      };
    }
    case "POST_REGISTER_CONTINUE": {
      return {
        ...state,
        registerContinueResponse: action.payload.RegisterContinueResponse,
        registerContinueMessage: action.payload.message,
      };
    }
    case "RESET_RESPONSE_SECOND": {
      return {
        ...state,
        registerContinueResponse: false,
        registerContinueMessage: "",
      };
    }
    case "IS_VALID_HASH_PASSWORD": {
      return {
        ...state,
        isValidHashPassword: action.payload.isValid,
      };
    }
    case "POST_REGISTER_NEW_PASSWORD": {
      return {
        ...state,
        passwordRedefineMessage: action.payload.message,
        passwordRedefineSuccess: action.payload.success,
      };
    }
    case "FORGOT_PASSWORD_RESPONSE": {
      return {
        ...state,
        passwordChangeRequestMessage: action.payload.message,
        passwordChangeRequestSuccess: action.payload.success,
      };
    }
    case "RESET_RESPONSE_PASSWORD": {
      return {
        ...state,
        passwordRedefineMessage: "",
        passwordRedefineSuccess: false,
      };
    }
  }
  return state;
};
