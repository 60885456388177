import { Register, Settings } from "src/model/myTypes";

export const InitialStateSettings: Settings = {
  userLang: process.env.REACT_APP_DEFAULT_LANG as string,
  appName: "",
  // navigator.language.indexOf("-") !== -1
  //   ? navigator.language.split("-")[0]
  //   : navigator.language,
};
export const InitialStateRegister: Register = {
  registerStartResponse: false,
  registerStartMessage: "",
  registerStartMessageErrorFields: "",
  usernameFree: false,
  isValidHash: true,
  hash: "",
  registerContinueResponse: false,
  registerContinueMessage: "",
  isValidHashPassword: false,
  passwordRedefineMessage: "",
  passwordRedefineSuccess: false,
  availableCurrencies: [],
  passwordChangeRequestSuccess: false,
  passwordChangeRequestMessage: "",
  requestDocumentsAddress: false,
  requestDocumentsIdentity: false,
  autoApproveUser: false,
  phoneNumberResgistration: false,
  birthdateResgistration: false,
  cpfResgistration: false,
  forceEmailValidation: false,
  preData: {
    birthdate: '',
    country: '',
    cpf: '',
  },
  useLegitimuz: false,
  agentRegisterMessage: ''
};

export const INITIAL_STATE = {
  register: InitialStateRegister,
  settings: InitialStateSettings,
};
