import { Action, Reducer } from "redux";
import { Settings } from "src/model/myTypes";
import { InitialStateSettings } from "./initial-state";
import { SettingsActions } from "../actions/action-settings";

const unloadedState: Settings = InitialStateSettings;

export const reducer: Reducer<Settings> = (
  state: Settings | undefined,
  incomingAction: Action
): Settings => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as SettingsActions;
  // console.log("action.type", action.type);
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      return {
        ...state,
        userLang: action.payload,
      };
    case "CHANGE_WEBSITE":
      return {
        ...state,
        appName: action.payload,
      };
  }
  return state;
};
